/**
 * 編集画面
 */
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { checkFileName } from "../variables/ManageRelated.js";
import NotFound from "./NotFound.js";
import CommonForm from "../components/CommonForm.js";
import ResistModalContent from "../components/ResistModalContent";

import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

function Edit({ getUserApiResult }) {
  const location = useLocation();
  const [id, setId] = useState();

  const [data, setData] = useState();
  const [loding, setLoding] = useState(true);

  const [thumbnailDel, setThumbnailDel] = useState(false);
  // パラメータない対応（URL直の接続）
  const [notFound, setNotFound] = useState(false);

  const [alertMessage, setAlertMessage] = useState("アップロード中...");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 画面初期表示のため、APIコール
  useEffect(() => {
    const variables = {
      filter: {
        id: {
          eq: location.state.id,
        },
      },
    };

    if (!location.state) {
      setNotFound(true);
      return;
    }
    setId(location.state.id);

    async function fetchData() {
      try {
        const infoPlaymovieResponse = await API.graphql({
          query: queries.playMovie,
          variables: variables,
        });

        // 取得したデータにアクセス
        setData(infoPlaymovieResponse.data.playMovie.method[0]);
        setLoding(false);
      } catch (error) {
        console.error("Error:", error);
        setLoding(false);
      }
    }

    if (location.state) fetchData();
    // eslint-disable-next-line
  }, []);

  /** 更新ボタンクリック処理*/
  const onSubmit = async (inputData) => {
    // ポップアップ表示、他の操作不可にする
    setIsModalOpen(true);
    setAlertMessage("アップロード中...");

    // DBに更新内容
    const editData = {
      videoId: id, // id
      registerDate: data.register_date, //　登録日
      videoTitle: inputData.title, //　タイトル
      videoTag: inputData.tag, //　タグ
      videoSubTag: inputData.subTags.split(",").map((tag) => tag.trim()),
      description: inputData.descriptions, //　動画概要
      videoUrl: data.video_url,
    };

    // 動画ファイル更新がある場合
    const editVideoFlg = inputData.video.length > 0;
    // サムネイルファイル更新がある場合
    const edithumbnailFlg = inputData.thumbnail.length > 0;

    // 動画ファイル更新がある場合の設定
    if (editVideoFlg) {
      editData.videoFile = checkFileName(inputData.video[0].name);
      // サムネイルファイル更新がある場合の処理
      if (edithumbnailFlg) {
        editData.thumbnailFile = checkFileName(inputData.thumbnail[0].name);
        editData.settingThumbnailUrl = data.setting_thumbnail_url;
      }
      // サムネイル削除された場合の処理
      else if (thumbnailDel) {
        editData.settingThumbnailUrl = data.setting_thumbnail_url;
      }
    } else {
      // 動画ファイルがなかった時の処理
      if (edithumbnailFlg) {
        // サムネイルファイル更新がある場合、リクエスト情報へ追加
        editData.thumbnailFile = checkFileName(inputData.thumbnail[0].name);
        editData.settingThumbnailUrl = data.setting_thumbnail_url;
      } else {
        if (thumbnailDel) {
          // サムネイル削除された場合の処理
          editData.settingThumbnailUrl = data.setting_thumbnail_url;
        }
      }
    }

    // DB更新APIコール
    const response = await API.graphql({
      query: mutations.editVideo,
      variables: {
        editVideoItem: editData, // 送信するデータを変数として渡す
      },
    });
    const result = response.data.editVideo;

    /** 動画ファイルアップロード
     */
    if (result.videoUrl) {
      try {
        const videoHeaders = {
          "Content-Type": "video/mp4",
        };
        const [baseUrl, queryParams] = result.videoUrl.split("?");
        const fullUrl = `${baseUrl}?${queryParams}`;
        const videoUploadResponse = await axios.put(
          fullUrl,
          inputData.video[0],
          {
            headers: videoHeaders,
            timeout: 900000, // 15分（900,000ミリ秒）でタイムアウト
          },
        );

        if (videoUploadResponse.status === 200) {
          console.log("動画のアップロードに成功しました");
        }
      } catch (error) {
        if (error.code === "ECONNABORTED") {
          console.error("アップロードがタイムアウトしました");
          setAlertMessage("アップロードがタイムアウトしました"); // モーダルにタイムアウトメッセージを表示
          return;
        } else {
          console.error(`動画のアップロードに失敗しました, error=${error}`);
        }
      }
    }
    /** 動画ファイルアップロード
     * @param url issueUrlのpresignedUrl
     * @param video　動画ファイル
     */
    if (inputData.thumbnail.length > 0) {
      try {
        const thumbnailHeaders = {
          "Content-Type": "upload/input/image/*",
        };

        const thumbnailUploadResponse = await axios.put(
          result.thumbnailUrl,
          inputData.thumbnail[0],
          { headers: thumbnailHeaders },
        );

        if (thumbnailUploadResponse.status === 200) {
          console.log("サムネイル画像のアップロードを完了しました");
        }
      } catch (error) {
        console.error(
          `サムネイル画像のアップロードに失敗しました, error=${error}`,
        );
      }
    }

    // 動画登録完了のポップアップメッセージを設定
    // todo:エラー対応
    setAlertMessage("アップロードが完了しました");
  };
  // idのパラメータが場合
  if (notFound) return <NotFound />;
  if (loding) return <div></div>;

  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        initVal={data}
        state="edit"
        setThumbnailDel={setThumbnailDel}
        getUserApiResult={getUserApiResult}
      />
      <ResistModalContent
        isOpen={isModalOpen}
        alertMessage={alertMessage}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
export default Edit;
