import {
  TextField,
  Container,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
// ドコパヘッダーコンポーネント
import DocopaHeader from "../components/DocopaHeader.js";
import { checkReq, explanText } from "../variables/ManageRelated.js";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";

import DeleteIcon from "@mui/icons-material/Delete";
// AppSync呼び出し用
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

const StyleHelpText = styled("span")(({ theme }) => ({
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

function CommonForm({
  onSubmit,
  initVal,
  state,
  setThumbnailDel,
  getUserApiResult,
}) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const descriptionMax = 2000;
  // 動画ファイルプレビュー
  const [videoUrl, setvideoUrl] = useState(null);
  // 更新の動画ファイルプレビュー
  const [videoImgUrl, setvideoImgUrl] = useState(null);
  const [videoText, setVideoText] = useState(null);
  // 画像ファイルプレビュー
  const [imageUrl, setImageUrl] = useState(null);
  const [imageText, setImageText] = useState(null);
  // 更新画面: 既存サムネイルある場合 true : 削除ボタン表示
  const [imageDelBtn, setImageDelBtn] = useState(false);
  // 更新画面: 削除ボタンクリック/ サムネイルへ別ファイル選択し true
  const [imageDelFlg, setImageDelFlg] = useState(false);
  const [submitText, setSubmitText] = useState(null);
  // getTagListリクエスト
  const [data, setData] = useState([]);

  // 登録・更新の動画・サムネイルのヘルパーテキスト/submitボタンの文言設定
  useEffect(() => {
    setVideoText(
      state === "edit"
        ? explanText.video.EditHelperText
        : explanText.video.helperText,
    );
    setImageText(
      state === "edit"
        ? explanText.thumbnail.EditHelperText
        : explanText.thumbnail.helperText,
    );
    setSubmitText(state === "edit" ? "更新" : "登録");
    setImageDelBtn(state === "edit" && !!initVal.setting_thumbnail_url);
    // eslint-disable-next-line
  }, []);

  // 動画ファイル検知
  const videoWatch = watch("video");
  useEffect(() => {
    clearErrors("video");
    if (videoWatch && videoWatch.length > 0) {
      state === "edit" && setValue("descriptions", "");
      setvideoUrl(URL.createObjectURL(videoWatch[0]));
      setvideoImgUrl(null);
      // 動画ファイルではない
      if (!videoWatch[0]?.type.startsWith("video/")) {
        setvideoUrl(null);
        setvideoImgUrl(null);
        setError("video", {
          message: explanText.video.otherFileErrText,
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setvideoUrl(state === "edit" ? initVal.video_url : null);
      setvideoImgUrl(state === "edit" ? initVal.thumbnail_url : null);
    }
    // eslint-disable-next-line
  }, [videoWatch, clearErrors, setError]);

  // サムネイルファイル検知
  const thumbnailWatch = watch("thumbnail");
  useEffect(() => {
    clearErrors("thumbnail");

    if (thumbnailWatch && thumbnailWatch.length > 0) {
      setImageDelBtn(false); // 別ファイルを選択すると、削除ボタンなくす
      setImageDelFlg(true);
      setImageUrl(URL.createObjectURL(thumbnailWatch[0]));
      // 画像ファイルではない
      if (!thumbnailWatch[0]?.type.startsWith("image/")) {
        setImageUrl(null);
        setError("thumbnail", {
          message: explanText.thumbnail.errText,
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setImageUrl(
        state === "edit" && !imageDelFlg ? initVal.setting_thumbnail_url : null,
      );
    }
    // eslint-disable-next-line
  }, [thumbnailWatch, clearErrors, setError]);

  // 動画概要検知
  const descriptionWatch = watch("descriptions");
  useEffect(() => {
    clearErrors("descriptions");
    if (descriptionWatch && descriptionWatch.length > descriptionMax) {
      setError("descriptions");
    }
  }, [descriptionWatch, clearErrors, setError]);

  // サブタグ検知
  const subTagsWatch = watch("subTags");
  useEffect(() => {
    clearErrors("subTags");
    if (subTagsWatch) {
      const subTagsValue = subTagsWatch.split(",").map((tag) => tag.trim());
      // サブタグの最大件数確認
      if (subTagsValue.length > 5) {
        setError("subTags", { message: explanText.subTags.errText });
      } else {
        // サブタグの長さ検知(15文字まで)
        for (const tag of subTagsValue) {
          if (tag.length > 15) {
            setError("subTags", {
              message: explanText.subTags.errMaxTagLength,
            });
          }
        }
      }
    }
  }, [subTagsWatch, clearErrors, setError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagListResponse = await API.graphql({
          query: queries.getTagList,
        });

        // データをidでソート
        const sortedData = tagListResponse.data.getTagList.data.sort(
          (a, b) => a.id - b.id,
        );

        setData(sortedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        getUserApiResult={getUserApiResult}
      />
      {data && data.length > 0 ? (
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="タイトル"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              <TextField
                name="title"
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={
                  errors.title
                    ? errors.title.message
                    : explanText.title.helperText
                }
                error={!!errors.title}
                {...register("title", {
                  required: explanText.title.errText,
                })}
                defaultValue={initVal.title}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="カテゴリ"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              {
                <FormControl
                  style={{ width: "80%", marginLeft: "7px", marginTop: "7px" }}
                >
                  <Select
                    name="tag"
                    control={control}
                    defaultValue={initVal.tag ? initVal.tag : ""}
                    variant="outlined"
                    {...register("tag", {
                      required: explanText.tag.errText,
                    })}
                    onChange={() => clearErrors("tag")}
                    error={!!errors.tag}
                  >
                    {data.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.tag}>
                    {errors.tag ? (
                      errors.tag.message
                    ) : (
                      <StyleHelpText>
                        動画のカテゴリを選択してください。(カテゴリを追加したい場合はSlackの「
                        <a
                          href="https://slb-sl.slack.com/archives/C060M0FKG06"
                          target="_blank"
                          rel="noreferrer"
                        >
                          #docopa_おすすめ動画周知
                        </a>
                        」チャンネルまでお問い合わせ下さい。)
                      </StyleHelpText>
                    )}
                  </FormHelperText>
                </FormControl>
              }
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="タグ"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <TextField
                name="subTags"
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={
                  errors.subTags
                    ? errors.subTags.message
                    : explanText.subTags.helperText
                }
                error={!!errors.subTags}
                {...register("subTags", {})}
                defaultValue={initVal.subtags}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="動画概要"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <TextField
                name="descriptions"
                id="outlined-multiline-static"
                multiline
                rows={4}
                helperText={
                  <>
                    <Typography variant="caption">
                      {`動画の説明文を入力してください。（${
                        descriptionWatch ? descriptionWatch.length : 0
                      } / ${descriptionMax}文字）`}
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      未入力の場合、AIにより自動生成された概要が表示されます。
                    </Typography>
                  </>
                }
                error={!!errors.descriptions}
                {...register("descriptions", {
                  maxLength: descriptionMax,
                })}
                defaultValue={initVal.description}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="動画ファイル"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              <div style={{ width: 550, float: "left" }}>
                <TextField
                  name="video"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="file"
                  inputProps={{
                    accept: "video/*",
                  }}
                  helperText={errors.video ? errors.video.message : videoText}
                  error={!!errors.video}
                  {...register("video", {
                    required:
                      state === "edit" ? false : explanText.video.nonErrText,
                    validate: {
                      acceptedFormats: (files) => {
                        if (files[0] && !files[0]?.type.startsWith("video/")) {
                          return explanText.video.otherFileErrText;
                        }
                      },
                    },
                  })}
                />
              </div>
              {!videoUrl ? (
                <NonFileBox />
              ) : (
                <video
                  src={videoUrl}
                  alt="アップロード画像"
                  poster={videoImgUrl}
                  style={{
                    width: "350px",
                    minHeight: "200px",
                    marginTop: "0.5rem",
                  }}
                />
              )}
            </Box>
            <div style={{ clear: "both" }} />
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="サムネイル"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <div style={{ dispay: "flex", alignItems: "center" }}>
                <div style={{ width: 550, float: "left" }}>
                  <TextField
                    name="thumbnail"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="file"
                    inputProps={{
                      accept: "image/*",
                    }}
                    helperText={
                      errors.thumbnail ? errors.thumbnail.message : imageText
                    }
                    error={!!errors.thumbnail}
                    {...register("thumbnail", {
                      validate: {
                        acceptedFormats: (files) => {
                          if (
                            files[0] &&
                            !files[0]?.type.startsWith("image/")
                          ) {
                            return explanText.thumbnail.errText;
                          }
                        },
                      },
                    })}
                  />
                  {imageDelBtn && (
                    <Button
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={() => {
                        setImageUrl(null);
                        setImageDelBtn(false);
                        setImageDelFlg(true);
                        setThumbnailDel(true);
                      }}
                    >
                      <DeleteIcon />
                      表紙画像の削除
                    </Button>
                  )}
                </div>
                {!imageUrl ? (
                  <NonFileBox />
                ) : (
                  <img
                    src={imageUrl}
                    alt="アップロード画像"
                    style={{
                      width: "350px",
                      minHeight: "200px",
                      marginTop: "0.5rem",
                    }}
                  />
                )}
              </div>
            </Box>
            <div style={{ clear: "both" }} />
            <Button
              variant="contained"
              sx={{ width: "100px", mx: "auto", my: "30px" }}
              type="submit"
            >
              {submitText}
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "100px", mx: "auto", my: "30px", marginLeft: "5px" }}
              onClick={() => navigate(`/manage`)}
            >
              戻る
            </Button>
          </form>
        </Container>
      ) : null}
    </>
  );
}
/**
 * @param title　ラベルテキスト
 * @param color　必須・任意のbgカラー
 * @param text 必須・任意
 * @returns 入力ラベル・必須・任意バッチ
 */
const TitleLine = ({ title, color, text }) => {
  return (
    <>
      <Typography
        sx={{
          width: "10%",
          float: "left",
          marginTop: "1.75rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "4%",
          float: "left",
          marginTop: "1.9rem",
          backgroundColor: color,
          color: "white",
          borderRadius: "5px",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

/** ビデオ・サムネイル未選択の場合表示 */
const NonFileBox = () => {
  return (
    <div
      style={{
        width: "350px",
        minHeight: "200px",
        float: "left",
        borderRadius: "5px",
        background: "#EEEEEE",
        marginTop: "0.5rem",
      }}
    >
      <p style={{ textAlign: "center" }}>プレビュー</p>
    </div>
  );
};

export default CommonForm;
